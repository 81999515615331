import React from "react";
import { HashLink } from 'react-router-hash-link';
import Affiliation from "./Affiliation";
import Publication from "./Publication";

function About() {
    

    return (
        // <div className="mx-5">
        <div>
            <h1 className="text-center">About</h1>
            <p>
                I am a curiosity-driven, analytical and conscientious person with an intense focus for intellectual challenges. I realized in 2015 that AI has a reasonable probability of being the most important technology in my lifetime and pivoted to it, leaving behind the original career trajectory I had in mind for the previous 9 years.

                Given the current pace of AI development, I am going <strong>all in</strong> on bringing about beneficial & capable AI in a safe manner. 
            </p>

            <hr></hr>
            <h2>Technical Interests</h2>
            <p>
                Looking to explore opportunities in these areas of AI, connect with me on LinkedIn for a discussion!
            </p>

            <ul>
                <li>Mechanistic & Developmental Interpretability</li>
                <li> Reasoning systems
                    <ul>
                        <li>Combining system 1 and system 2: eg Cognitive Architectures, Neuro-Symbolic AI for efficient learning and explainability</li>
                        <li>Efficient (Curriculum?) learning and reasoning by combining varied sources and methods eg multimodal data, knowledge / scene graphs, books, wikis, commonsense model via mining/interacting with the web, reinforcement learning</li>
                    </ul>
                </li>
                <li>Applications to scientific discovery, theorem proving / mathematical reasoning and code generation</li>
            </ul>

            <hr></hr>
            <h2>Work Experience</h2>

            <Affiliation imgName={""} title={"Gov Contractor"} subtitles={["Senior Research Scientist, Artificial Intelligence"]} points={[<>Demonstrated the first-ever 100 frames-per-second car detection algorithm which works under motion blur and poor lighting, using self-supervised learning to overcome the lack of labels. Published in CVPR 2018 Embedded Vision Workshop. [<HashLink smooth to="#ebw2018">Publication</HashLink>]</>, <> Developed an algorithm to show the efficacy of using scene-graphs for video anomaly classification, achieving ~84% of the performance of end-to-end deep learning with the added benefit of interpretability and simplicity. Published in NeurIPS 2018 Visually Grounded Interaction & Language Workshop. [<HashLink smooth to="#vigil2018">Publication</HashLink>]</>, <><strong>Skills: </strong> Rapid evaluation and prototyping of AI algorithms. Computer Vision, Multimodal (Video/Image-Text) Analysis. Natural Language Processing: Document Classification, Topic Detection and Tracking. Meta-Learning. Network Analysis. Frontiers, e.g. beyond pattern recognition, Neuro-Symbolic AI. Neuromorphic AI.</>]} />

            <Affiliation imgName={"nus-logo-white-b-stack.png"} title={"National University of Singapore"} subtitles={["Adjunct Research Scientist, Neuromorphic Artificial Intelligence"]} points={[<>Explored methods that convert frame-based data to spikes for training Spiking Neural Networks, enabling high speed, low power + data requirement image/video classification. Improved data efficiency by ~100X while maintaining ~80% accuracy.</>, <>Contributed to <a target="_blank" href="https://github.com/bamsumit/slayerPytorch">slayerPytorch</a>, a PyTorch implementation of the <a target="_blank" href="https://arxiv.org/abs/1810.08646">SLAYER</a> algorithm for training spiking neural networks. Updated library to handle channels in image and video (data processing, animating, test cases) </>]}/>
            


{/* 
            <h2>Internships</h2>
            Logos + brief description in card form

            Rutherford Appleton Lab: Used neural networks to interpret experimental data (proton radiographs), allowing one to be able to reconstruct 3-D magnetic fields in high energy density plasmas from radiographs for the first time. This has implications in understanding astrophysical systems, diagnosing nuclear fusion reactions and creating new medical treatments. Published in Physical Review E. (TODO: Link here!)

            Oxford:  Designed and built an experimental set up to generate multiple telecomms-band photons with applications in large scale quantum information processing.

            Oxford:  Worked on an experimental technique to characterize photons, with applications in quantum information processing and testing models of physical reality. Wrote Matlab scripts to analyze experimental data.

            Enactus Oxford: Finance Director. , fundraisers & grants.

            Built and maintained the society's & project's websites, used data analytics to identify trends in member engagement and business sales. started and led the society's Tech Support Team.

            Worked in teams to help the homeless & disabled earn an income. Initiated a project to promote computer science among young females.

            Centre for Quantum Technologies, Singapore

            July 2009 – November 2009 (Full-Time): Worked on Rubidium Spectroscopy which has applications in tuning of lasers, measuring physical distances and detecting chemical compositions in different parts of Earth's atmosphere.

            January 2009 – November 2009 (Part-Time): Explored Quantum Games (e.g. Quantum Prisoner's Dilemma) and Quantum Game Theory.

            Data Storage Institute, Singapore

            January 2008 – December 2008 (Part-Time): "Berry's Phase for the Imaging of Micromagnetics System" Proposed a novel way to perform quality control on patterned media (hard disks of the future) and reduced the process time from a few hours to a few seconds.

            November 2008 – December 2008 (Full-Time): "Fabrication And Characterization Of Nano Magnetic Patterns" Optimized temperature and pressure parameters for a process which mass produces patterned media (hard disks of the future)

            January 2007 – December 2007 (Part-Time): "Spin Mechatronics For Application In Spintronics And Magnetic Memory" Proposed a new device which addresses feasibility issues of Magnetic Random Access Memory (MRAM)

            October 2006 – December 2006 (Full-Time): "Larmor Precession & Barrier Tunneling Time Of A Neutral Spinning Particle" Investigated claims of apparent faster-than-light phenomena in neutral particles. Covered basic Undergraduate Quantum Physics while I was 14 years old. */}
            <hr></hr>
            <h2>Education</h2>

            <Affiliation imgName={"oxweb-logo.gif"} title={"University of Oxford"} subtitles={["Master of Mathematical and Theoretical Physics, Distinction", "Bachelor's in Physics, First Class Honours"]} points={[<><strong>Relevant Coursework:</strong> Physics, Scientific Computing (Numerical Methods and Simulation), Mathematical Methods, Machine Learning, Applied Mathematics</>, <><strong>Projects: </strong> (i) Simulating the global maritime network using a modified preferential attachment model. (ii) Explosive percolation and its applicability to real-world networks.</>, <><strong>Honors: </strong>St Edmund Hall Open Scholarship for excellent standard of work (all 4 years)</>, <><strong>Master's Thesis: </strong> Used AI to analyze proton radiographs, with applications in astrophysics, nuclear energy and medicine. Published in Physical Review E 2017. [<HashLink smooth to="#pr2017">Publication</HashLink>]</>]}/>

            {/* 
            <strong>NUS Advanced Computing For Executives</strong><br/>
            <i>Professional Certificate in Data Structures and Algorithms</i><br/>
            <p>[<a target="_blank" href="https://credentials.nus.edu.sg/73eebd43-69bf-4dd1-9d29-f0e7f882420e">Cert</a>]</p>
            
            <strong>Johns Hopkins University, Coursera (Online)</strong><br/>
            <i>Data Science Specialisation</i><br/>
            <ul>
                <li>
                    <strong>Relevant Coursework: </strong> The Data Scientist’s Toolbox, R Programming, Getting and Cleaning Data, Exploratory Data Analysis, Reproducible Research, Statistical Inference, Regression Models, Practical Machine Learning, Developing Data Products, Data Science Capstone (Creating text prediction engine via Natural Language Processing)
                </li>
            </ul>

            <strong>Udacity AI Nanodegree</strong><br/>

            <strong>Udacity Data Analyst Nanodegree</strong><br/> */}
            <hr></hr>
            <h2>Publications & Conferences</h2>

            <Publication id="vigil2018" imgName="vigil2018Thumbnail.png" authors={<>N. <strong>Chen</strong>, Z. Du, K.H. Ng</>} title="Scene Graphs for Interpretable Video Anomaly Classification" details={<>Conference on Neural Information Processing Systems Workshop on Visually Grounded Interaction and Language, 3-8 December 2018 (Poster Session)</>} links={[["https://nips2018vigil.github.io/static/papers/accepted/30.pdf", "Paper"], ["https://nips2018vigil.github.io", "Workshop"]]} />

            <Publication id="ebw2018" imgName="pseudolabel2018Thumbnail.png" authors={<>N. <strong>Chen</strong></>} title="Pseudo-labels for Supervised Learning on Dynamic Vision Sensor Data, Applied to Object Detection under Ego-motion" details={<>The IEEE Conference on Computer Vision and Pattern Recognition (CVPR) Embedded Vision Workshop, 18-22 June 2018 (Poster Session)</>} links={[["https://openaccess.thecvf.com/content_cvpr_2018_workshops/papers/w12/Chen_Pseudo-Labels_for_Supervised_CVPR_2018_paper.pdf", "Paper"], ["https://openaccess.thecvf.com/content_cvpr_2018_workshops/supplemental/Chen_Pseudo-Labels_for_Supervised_CVPR_2018_supplemental.pdf", "Supplemental"], ["https://www.youtube.com/watch?v=6QHP7xhcYx0", "Sample Clip (Demo)"], ["https://openaccess.thecvf.com/CVPR2018_workshops/CVPR2018_W12", "Workshop"]]} />

            <Publication id="pr2017" imgName="pr2017Thumbnail.png" authors={<>N. <strong>Chen</strong>, M. F. Kasim, L. Ceurvorst, N. Ratan, J. Sadler, M. C. Levy, R. Trines, R. Bingham, and P. Norreys</>} title="Machine learning applied to proton radiography of high-energy-density plasmas" details={<>Physical Review E <strong>95</strong> (4), 043305 (2017)</>} links={[["https://doi.org/10.1103/PhysRevE.95.043305", "Paper"]]} />

            <Publication id="" imgName="" authors={<>M. F. Kasim, L. Ceurvorst, N. Ratan, J. Sadler, N. <strong>Chen</strong>, A. Sävert, R. Trines, R. Bingham, P. N. Burrows, M. C. Kaluza, and P. Norreys</>} title="Quantitative shadowgraphy and proton radiography for large intensity modulations" details={<>Physical Review E <strong>95</strong> (2), 023306 (2017)</>} links={[["https://doi.org/10.1103/PhysRevE.95.023306", "Paper"]]} />

            <Publication id="" imgName="" authors={<>C. Lee, S. Tan, M.A. Jalil, S.G. Wu, and N. <strong>Chen</strong></>} title="Spatial Micromagnetic Imaging via the Topological Hall Effect" details={<>in the 55th Magnetism and Magnetic Materials Conference, Atlanta, 14-18 November 2010, Abstract Digest page 300, R -398-000-061-305, Magnetic Microscopy and Instrumentation (Poster Session) Reference DU-01</>} links={[["https://events-siteplex.confcats.io/magnetism/wp-content/uploads/sites/82/2022/02/mmm_conference_55.pdf", "Conference Info"]]} />

            <Publication id="" imgName="" authors={<>S. Kumar, N. <strong>Chen</strong>, S. Tan, and M. Jalil</>} title="Spin Mechatronics to facilitate Spin Transfer Switching in Magnetic Tunnel Junction" details={<> in IEEE International Magnetics Conference (Intermag), Madrid, 4-8 May 2008, Spin Torque Excitations In Nanomagnets (Poster Session) Reference GT-05</>} links={[["http://www.intermagconference.com/intermag2008/datos/pdf/booklet.pdf", "Conference Info"]]} />
            
            <hr></hr>
            <h2>Skills</h2> 
            <ul>
                <li>
                    <strong>Languages: </strong> Python, JavaScript, R, Matlab, C 
                </li>
                <li>
                    <strong>AI/ML/Data Frameworks: </strong> PyTorch, LangChain, Pandas, scikit-learn, NetworkX
                </li>
                <li><strong>Frontend: </strong> HTML, CSS, React</li>
                <li><strong>Backend: </strong>Express, Node.js, MongoDB
                </li>
            </ul>

            <hr></hr>
            <h2>Other</h2>
            <strong>Microsoft for Startups Ideate Grant</strong><br/>
            <strong>Intel International Science & Engineering Fair 2009 - Finalist</strong><br/>
            <strong>First Step To Nobel Prize In Physics 2009 (Research Papers) - 2 Honourable Mentions</strong> Institute of Physics (Polish Academy of Sciences)<br/>
            {/* <strong>Winner of the Most Outstanding Junior College Science Student Award 2009</strong> World Scientific Publishing Company and the Imperial College Alumni Association of Singapore<br/> */}

        </div>
    )
}

export default About;