import React from "react";
import { Navbar, Nav, Container } from 'react-bootstrap';
import { Link } from "react-router-dom";
import SocialLink from "./SocialLink";
import { socialLinks } from "./socialLinks.config";

const NavigationBar = () => {

  return (
      <Navbar style={{backgroundColor: "#000000", position: "sticky"}} collapseOnSelect fixed='top' expand='md' variant='dark'>
      {/* <Navbar style={{position: "sticky"}} collapseOnSelect fixed='top' expand='md' variant='dark'> */}
        <Container fluid style={{ display: 'flex', alignItems: 'center' }}>
          <Navbar.Brand href="/" style={{ display: 'flex', alignItems: 'center' }}>
            <h3 className="mb-0">
              Nicholas Chen
            </h3>
          </Navbar.Brand>
          
          {/* Social links with flexbox styling */}
          <Nav className="me-auto" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {socialLinks.map(link => (
              <SocialLink key={link.name} {...link} />
            ))}
          </Nav>
          
          <Navbar.Toggle aria-controls='responsive-navbar-nav' />
          <Navbar.Collapse id='responsive-navbar-nav'>
            <Nav style={{ alignItems: 'center' }}>
              <Link className="nav-link" to="/Projects"><strong>Projects</strong></Link>
              {/* <Link className="nav-link" to="/About">About</Link> */}
              <Link className="nav-link" to="/Talks"><strong>Talks</strong></Link>
              <Link className="nav-link" to="/Ideation"><strong>Ideation</strong></Link>
            </Nav>
          </Navbar.Collapse>

        </Container>
      </Navbar>
  );
}

export default NavigationBar;