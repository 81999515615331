import React from "react";
import { HashLink } from 'react-router-hash-link';
import Affiliation from "./Affiliation";
import Publication from "./Publication";
import YoutubeEmbed from "./YoutubeEmbed";


function Talks() {
    // Reusable links
    const agiNotesLink = "https://github.com/nicholaschenai/agi-potential-notes";
    const coalaDemoLink = "https://github.com/nicholaschenai/coala_coder_demo";
    const coalaVideoLink = "https://www.youtube.com/watch?v=0iA6E0Wmgao";
    const coalaVideoPart3Link = "https://www.youtube.com/watch?v=48ipCrKtd6Y";
    const coalaVideoPlaylistLink = "https://www.youtube.com/watch?v=0iA6E0Wmgao&list=PLnornnv3hCj0T8Vi8RO8VJ9UwyOhsvNLW";
    const reasoningSystemsVideoLink = "https://www.youtube.com/embed/nZC_xXIgVBo?start=4663&end=6787";

    return (
        // <div className="mx-5">
        <div>
            <h1 className="text-center">Talks</h1>

            <br/><hr></hr><br/>

            {/* New format talks */}
            <div>
                <ul style={{ listStyleType: 'disc', marginBottom: '1.5rem' }}>
                    <li style={{ marginBottom: '1rem' }}>
                        <strong>19 Mar 2025</strong>. <strong>Lorong AI Paper Club</strong>. Paper sharing: Cognitive Architectures for Language Agents.
                        [<a target="_blank" href={coalaVideoPlaylistLink}>video</a>]
                        [<a target="_blank" href={`${agiNotesLink}/blob/main/papers/coala.md`}>notes</a>] 
                        [<a target="_blank" href="https://github.com/nicholaschenai/cognitive_base">repo</a>]
                    </li>

                    <li style={{ marginBottom: '1rem' }}>
                        <strong>01 Mar 2025</strong>. <strong>smol hackathon for smol models, Antler SG</strong>. Locally hosted tasks pipeline (3rd place winner). 
                        [<a target="_blank" href="https://github.com/nicholaschenai/local-task-pipeline">repo</a>] 
                        [<a target="_blank" href="https://github.com/nicholaschenai/productivity-tools">notes</a>]
                    </li>

                    <li style={{ marginBottom: '1rem' }}>
                        <strong>27 Feb 2025</strong>. <strong>Singapore AI Safety Hub</strong>. An overview of Agent Security. 
                        [<a target="_blank" href="https://github.com/nicholaschenai/agent-security-notes">notes</a>]
                    </li>

                    <li style={{ marginBottom: '1rem' }}>
                        <strong>10 Jan 2025</strong>. <strong>AI Tinkerers Singapore Meetup, AWS</strong>. Cognitive Architectures – A principled way to build agents. 
                        [<a target="_blank" href={coalaDemoLink}>demo</a>]
                    </li>

                    <li style={{ marginBottom: '1rem' }}>
                        <strong>29 Oct 2024</strong>. <strong>A*STAR CFAR / I2R</strong>. Cognitive Architectures + Agents. 
                        [<a target="_blank" href={coalaVideoPlaylistLink}>video series</a>] 
                        [<a target="_blank" href={agiNotesLink}>notes</a>]
                    </li>

                    <li style={{ marginBottom: '1rem' }}>
                        <strong>20 Feb 2024</strong>. <strong>MLSG meetup, Google SG</strong>. LLMs + Cognitive Architecture = Generalist Agents?
                        [<a target="_blank" href={coalaVideoLink}>Video 1</a>] 
                        [<a target="_blank" href={coalaVideoPart3Link}>Video 2</a>] 
                        [<a target="_blank" href={agiNotesLink}>notes</a>]
                    </li>

                    <li style={{ marginBottom: '1rem' }}>
                        <strong>13 Oct 2023</strong>. <strong>NUS</strong>. Towards Reasoning Systems with LLMs. 
                        [<a target="_blank" href={reasoningSystemsVideoLink}>video</a>]
                    </li>
                </ul>
            </div>

            <br/><hr></hr><br/>

            {/* Old format talks - commented out for reference */}
            {/* <h1>LLMs + Cognitive Architecture = Generalist Agents? </h1>
            MLSG meetup, Google SG, 20240220.
            
            <ul>
                <li><a target="_blank" href="https://github.com/nicholaschenai/agi-potential-notes">Notes on papers discussed during the talk</a></li>
                <li><HashLink smooth to="#reasoning-systems">Voyager & Gen Agents video</HashLink></li>
            </ul>

            <br/><hr id="reasoning-systems"></hr><br/>
            <h1>Towards Reasoning Systems with LLMs </h1>
            NUS, 20231013.

            (1:17:44 mark)
            <YoutubeEmbed link="https://www.youtube.com/embed/nZC_xXIgVBo?start=4663&end=6787" /> */}
        </div>
    )
}

export default Talks;