import React from 'react';
import { Nav } from 'react-bootstrap';

const SocialLink = ({ url, svgPath, iconClass }) => (
    // minor note, google uses class rather than className, just note here for future reference in case of errors
  <Nav.Link href={url} target="_blank" className="px-2">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={iconClass} viewBox="0 0 16 16">
      <path d={svgPath} />
    </svg>
  </Nav.Link>
);

export default SocialLink; 